import React, { useEffect, useState } from "react";
import { chunk, isEmpty, keyBy } from "lodash";
import { Input, notification, Tooltip } from "antd";
import { AlertDecagramIcon } from "mdi-react";
import { useMediaQuery } from "react-responsive";
import { diff } from "deep-object-diff";

// styling
import { css } from "emotion";
import colors from "../../../style/colors";
// utlities
import req from "../../../utilities/request-utility";
import getErrorMessage from "../../../utilities/get-error-message";

// ui-Components
import DropDown from "../../ui/DropDown";
import TextInput from "../../ui/TextInput";
import TextareaInput from "../../ui/TextareaInput";
import Button from "../../ui/Button";
import { useDispatch, useSelector } from "react-redux";
import ScrollView from "../../ui/ScrollView";
import ImageUploadMultiple from "../ImageUploadMultiple";
import { hideModalPage } from "../../../actions/uiActions";

import FormItem from "../../ui/FormItem";
import InlineSpinner from "../../ui/InlineSpinner";

import useOverviewData from "./hooks/useOverviewData";

function PunchModal({
  refreshData,
  initialFormData = {},
  testSheetData = {},
  editMode = false,
  testSheetMode = false,
  afterFormSubmitHook = () => {},
}) {
  const dispatch = useDispatch();
  const { areaCode = null, project = null } = useSelector(({ semcompletion }) => ({
    areaCode: semcompletion.selectedProjectArea,
    project: semcompletion.selectedProject,
  }));
  const { user = null } = useSelector(({ auth }) => ({ user: auth.user }));

  const isTabletPortrait = useMediaQuery({ orientation: "portrait", maxWidth: 820 });
  const isIpadMiniLandscape = useMediaQuery({ orientation: "landscape", minWidth: 900, maxWidth: 1024 });

  const originalFormData = {
    punchId: initialFormData.punchId || "",
    user: initialFormData.user || "",
    assignee: initialFormData.assignee || "",
    punchList: initialFormData.punchList || "",
    punchOwner: initialFormData.punchOwner || "",
    punchOrigin: initialFormData.punchOrigin || "",
    punchCategory: initialFormData.punchCategory || "",
    platformLocation: initialFormData.platformLocation || "",
    platformLocationText: initialFormData.platformLocationText || "",
    workType: initialFormData.workType || "",
    tagNumber: initialFormData.tagNumber || "",
    system: initialFormData.system || "",
    area: initialFormData.area || "",
    title: initialFormData.title || "",
    description: initialFormData.description || "",
    name: user ? user.name : "",
    images: initialFormData.images || [],
  };
  const [formData, setFormData] = useState(originalFormData);
  const [formLoading, setFormloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const { assigneeListRefetch, metaData, metaDataRefetch, users } = useOverviewData(formData.punchList);
  const usersObj = keyBy(users, "id");

  const isUserInPunchList = !editMode ? true : usersObj[formData.user] ? true : false;

  useEffect(() => {
    if (metaData.loading) return;
    if (!isEmpty(initialFormData)) return;

    // Set defaults to punchList
    let defaultPunchList = "";
    if (!initialFormData.punchList && metaData.data.punchLists.length !== 0 && metaData.data.punchLists.length === 1)
      defaultPunchList = metaData.data.punchLists[0].id;

    // This sets up all the data needed when adding punches from a testsheet
    if (testSheetMode === true) {
      let [preSelectedCategory] = metaData.data.punchCategories.filter((c) => c.name.toLowerCase() === "b");
      let [preSelectedOrigin] = metaData.data.punchOrigins.filter((o) => o.name.toLowerCase() === "construction");

      setFormData({
        ...formData,
        punchCategory: preSelectedCategory ? preSelectedCategory.id : "",
        punchList: defaultPunchList,
        punchOrigin: preSelectedOrigin ? preSelectedOrigin.id : "",
        taskId: testSheetData ? testSheetData.taskId : "",
        tagNumber: testSheetData ? testSheetData.cableNumber : "",
        jobPackageId: testSheetData ? testSheetData.jobPackageId : "",
        questionAnswerValue: testSheetData ? testSheetData.answerValue : "",
        questionId: testSheetData ? testSheetData.questionId : "",
        name: user ? user.name : "",
      });
    } else {
      setFormData({ ...formData, punchList: defaultPunchList, name: user ? user.name : "" });
    }

    // eslint-disable-next-line
  }, [JSON.stringify(metaData), JSON.stringify(initialFormData)]);

  const getUploadState = (uploadState) => setIsUploading(uploadState);

  function isFormValid(formDate) {
    if (
      !formDate.punchList ||
      !formDate.punchOwner ||
      !formDate.punchOrigin ||
      !formDate.punchCategory ||
      !formDate.workType ||
      !formDate.system ||
      !formDate.name ||
      !formDate.title ||
      !formDate.area
    ) {
      return {
        status: false,
        message: "Make sure to fill out all the required fields and then try again",
      };
    } else if (!formDate.workType && !formDate.description) {
      return {
        status: false,
        message: "Make sure to fill out at least issue type or description and then try again",
      };
    }

    return { status: true };
  }

  function savePunch(type) {
    const isCreateAndDuplicate = type === "createAndDuplicate";

    const isFormValidData = isFormValid(formData);

    if (!isFormValidData.status)
      return notification.error({ duration: 7, message: "FAILED", description: isFormValidData.message });

    setFormloading(true);

    if (editMode) {
      req().put(`semcompletion/v2/punches/${initialFormData.id}`, formData).then(handleOk).catch(handleError);
    } else {
      req().post(`semcompletion/v2/punches/`, formData).then(handleOk).catch(handleError);
    }

    function handleOk() {
      if (!isCreateAndDuplicate) dispatch(hideModalPage());
      if (isCreateAndDuplicate) setFormData((data) => ({ ...data, description: "", images: [] }));
      if (refreshData) refreshData();
      afterFormSubmitHook();
      setFormloading(false);

      metaDataRefetch();
      notification.success({
        duration: 7,
        message: "SUCCESS",
        description: `You have successfully ${editMode ? "updated" : "added"} a punch`,
      });
    }

    function handleError(err) {
      const error = getErrorMessage(err);

      notification.error({ duration: 7, message: "FAILED", description: error });

      afterFormSubmitHook();
      setFormloading(false);
    }
  }

  function onChange(e) {
    let additionalData = {};

    if (e.target.name === "user") additionalData = { assignee: e.target.value ? usersObj[e.target.value].name : null };

    setFormData({
      ...formData,
      [e.target.name]: typeof e.target.value !== "undefined" ? e.target.value : null,
      ...additionalData,
    });

    if (e.target.name === "punchList") assigneeListRefetch();
  }

  useEffect(() => {
    if (areaCode.id && !editMode) onChange({ target: { name: "area", value: areaCode.id } });
  }, [areaCode.id]);

  const form =
    metaData.loading !== 0
      ? chunk(
          [
            initialFormData.punchId
              ? { disabled: true, id: "punchId", label: "Punch no", required: true, type: "text" }
              : null,
            {
              id: "punchList",
              label: "Punch list",
              options: metaData.data.punchLists,
              type: "dropdown",
            },
            { id: "punchOwner", label: "Punch owner", options: metaData.data.punchOwners, type: "dropdown" },
            { id: "punchOrigin", label: "Punch origin", options: metaData.data.punchOrigins, type: "dropdown" },
            { id: "punchCategory", label: "Punch category", options: metaData.data.punchCategories, type: "dropdown" },
            {
              id: "platformLocation",
              label: "Punch location",
              options: metaData.data.platformLocations,
              type: "dropdown",
            },
            { id: "workType", label: "Issue type", options: metaData.data.workTypes, type: "dropdown" },
            { id: "system", label: "System", options: metaData.data.systems, type: "dropdown" },
            { id: "area", label: "Area Code", options: metaData.data.areas, type: "dropdown", required: true },
            { id: "user", label: "Assignee", options: users, type: "dropdown" },
            { id: "tagNumber", label: "Tag number / Procedure", type: "text" },
          ].filter((d) => d),
          2
        )
      : [];

  const hasChanges = Object.keys(diff(originalFormData, formData)).length > 0;

  return (
    <ScrollView className={container()}>
      {form.map((row, index) => (
        <div className="row" key={`form-row-${index}`}>
          {row.map((column) => {
            let { disabled = false, id, label, options = [], required = false, type } = column;

            const isRequired =
              id === "punchList" ||
              id === "punchOrigin" ||
              id === "punchOwner" ||
              id === "punchCategory" ||
              id === "workType" ||
              id === "system" ||
              required;

            return (
              <div className="col-50" key={label}>
                <FormItem
                  label={
                    id === "user" ? (
                      <span style={{ display: "flex", gap: 5 }}>
                        {label}
                        {!isUserInPunchList && (
                          <Tooltip title="Current selected assignee does not have an access to the selected punch list">
                            <AlertDecagramIcon size={20} />
                          </Tooltip>
                        )}
                      </span>
                    ) : (
                      label
                    )
                  }
                  required={isRequired}
                >
                  {type === "dropdown" && (
                    <DropDown
                      allowClear={!required}
                      disabled={id === "user" && users.length === 0}
                      name={id}
                      onChange={onChange}
                      options={options ? options.map((d) => ({ label: d.name, value: d.id })) : []}
                      placeholder="Click to choose"
                      value={formData[id]}
                    />
                  )}
                  {type === "text" && (
                    <TextInput
                      placeholder={`Enter ${label}`}
                      disabled={disabled}
                      name={id}
                      onChange={onChange}
                      value={formData[id]}
                    />
                  )}
                </FormItem>
              </div>
            );
          })}
        </div>
      ))}

      <div className="row">
        <div className="col-100">
          <FormItem label="Title" required={true}>
            <Input maxLength={100} name="title" onChange={onChange} showCount size="large" value={formData.title} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Description" required={true}>
            <TextareaInput value={formData.description} name="description" onChange={onChange} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Images and PDFs">
            <ImageUploadMultiple
              placeholder="Upload Images and PDFs"
              allowedAmountOfImages={5}
              style={{ marginBottom: "0.75rem" }}
              urlPrefix="/semcompletion"
              uploadedFiles={formData && formData.images}
              onFileUpdate={(files) => {
                setFormData({ ...formData, images: files });
              }}
              isTabletPortrait={isTabletPortrait}
              isIpadMiniLandscape={isIpadMiniLandscape}
              getUploadState={getUploadState}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Name" required={true}>
            <TextInput value={formData.name} name="name" onChange={onChange} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className={editMode ? "col-100" : "col-50"}>
          <Button
            disabled={formLoading || isUploading || !hasChanges || !isUserInPunchList}
            onClick={() => {
              if (!project) {
                notification.error({
                  duration: 7,
                  message: "FAILED",
                  description: `There's no selected Project, please select from the main menu`,
                });
              } else {
                savePunch("create");
              }
            }}
          >
            {formLoading && <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />}{" "}
            {editMode ? "Save" : "Create"} Punch
          </Button>
        </div>
        {!editMode && (
          <div className="col-50">
            <Button
              disabled={formLoading || isUploading || !hasChanges || !isUserInPunchList}
              onClick={() => {
                if (!project) {
                  notification.error({
                    duration: 7,
                    message: "FAILED",
                    description: `There's no selected Project, please select from the main menu`,
                  });
                } else {
                  savePunch("createAndDuplicate");
                }
              }}
            >
              {formLoading && (
                <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
              )}
              Create and Duplicate Punch
            </Button>
          </div>
        )}
      </div>
    </ScrollView>
  );
}

const container = () => css`
  background-color: ${colors.white};
  padding: 1rem 0;

  label {
    font-weight: 700;
    margin-bottom: 0.35rem;
    display: block;
  }

  .row {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 1.25rem auto;

    .col-50 {
      flex-basis: 49%;
    }

    .col-100 {
      flex-basis: 100%;
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;

      .col-50 {
        flex-basis: 100%;

        &:nth-child(odd) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }
`;

export default PunchModal;
